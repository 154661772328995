
import { Component, Vue, Watch } from "vue-property-decorator"
import { ResetPasswordMutation, ResetPasswordMutationMutation } from "@/gql"
import { FetchResult } from "apollo-link"

@Component
export default class ResetPassword extends Vue {
  readonly ResetPasswordMutation = ResetPasswordMutation

  token = ""
  password = ""
  confirmPassword = ""

  mutationDone(result: FetchResult<ResetPasswordMutationMutation>) {
    if (result.data) {
      if (!result.data.resetPassword!.error) {
        this.token = ""
        this.password = ""
        this.confirmPassword = ""

        this.$router.push({ name: this.$routes.Home })
        this.addSuccess("Password reset successfully!")
      } else {
        this.addMutationError(result.data.resetPassword!.error)
      }
    }
  }

  mounted() {
    this.setTokenFromRoute()
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    this.setTokenFromRoute()
  }

  setTokenFromRoute() {
    if (this.$route.query.token) {
      const token: string = this.$route.query.token as string
      this.token = token
    }
  }
}
